<template>
    <div class="q-pa-md">
        <q-card style="min-width: 370px">
          <q-card-section>
            <div class="row no-wrap items-center rounded-borders">
                <div class="text-h6">
                    Constanza
                </div>
                <q-space />
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-text-recognition"
                    @click="ModoTexto()">
                    <q-tooltip content-class="primary" content-style="font-size: 16px" :offset="[10, 10]">
                        Regresar modo texto
                    </q-tooltip>
                </q-btn>
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="forum"
                    @click="NuevoChat()">
                    <q-tooltip content-class="primary" content-style="font-size: 16px" :offset="[10, 10]">
                        Nueva conversación
                    </q-tooltip>
                </q-btn>
            </div>
            <div class="element-linea"></div>
        </q-card-section>

          <q-card-section>
            <q-btn color="primary" icon="mic" label="Grabar" :disable="isRecording" @click="startRecording" class="q-mt-md" />
            <q-btn color="negative" icon="stop" label="Detener" :disable="!isRecording" @click="stopRecording" class="q-mt-md"/>
            <q-btn color="green" icon="mdi-reload" label="Repetir respuesta" @click="reproducirTexto" class="q-mt-md" v-if="respuesta != ''"/>
          </q-card-section>
          <q-card-section>
            <div class="barra-container q-mt-md">
                <div class="barra" :style="{ width: barraProgreso + '%', background: 'linear-gradient(to right, blue, black)' }">
                </div>
              </div>
        </q-card-section>

          <!-- <q-card-section>
            <div class="text-h6">Oir Audio</div>
            <q-btn color="green" icon="mic" label="Escuchar demo" @click="reproducirTexto" class="q-mt-md" />
          </q-card-section>
          <q-card-section>
            <div class="text-subtitle1 q-mt-md">Transcripción:</div>
            <q-banner v-if="transcript" class="q-mt-md">
              {{ transcript }}
            </q-banner>
          </q-card-section> -->
        </q-card>
    </div>
  </template>
  
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { format } from "date-fns";
import constanzaVoz from './ConstanzaVoz'
export default {
    data() {
        return {
            //PARA HABLAR
            recognition: null,
            isRecording: false,
            transcript: '',

            // RECIBE RESPUESTA
            loadingMensaje: false,
            respuesta: "",

            //barra de progeso
            barraProgreso: 0, // Porcentaje de progreso
            synth: null,
            utterance: null,
            animacionInterval: null,

            //HISTORIAL DEL CHAT
            chat_id: "",
            banderaNuevo: false,
        };
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemsPacientes() {
            return this.$store.state.listaPacienteStore
        },

    },

    created() {
        this.GetHistorialChat();
    },

    methods: {
        startRecording() {
            if (!('webkitSpeechRecognition' in window)) {
                this.$q.notify({ type: 'negative', message: 'API de reconocimiento de voz no disponible en este navegador.' });
                return;
            }

            this.recognition = new window.webkitSpeechRecognition();
            this.recognition.lang = 'es-ES'; // Cambiar el idioma si es necesario
            this.recognition.continuous = false; // Finaliza cuando detecta silencio
            this.recognition.interimResults = false;

            this.recognition.onstart = () => {
                this.isRecording = true;
                // this.$q.notify({ type: 'info', message: 'Grabando audio...' });
            };

            this.recognition.onresult = (event) => {
                const transcript = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join('');
                this.transcript = transcript;
                // this.$q.notify({ type: 'positive', message: 'Texto transcrito: ' + transcript });
                this.sendTranscript(transcript); // Llama al método para enviar la transcripción
            };

            this.recognition.onerror = (event) => {
                this.$q.notify({ type: 'negative', message: 'Error durante la grabación: ' + event.error });
            };

            this.recognition.onend = () => {
                this.isRecording = false;
                // this.$q.notify({ type: 'info', message: 'Grabación finalizada.' });
            };

            this.recognition.start();
        },

        stopRecording() {
            if (this.recognition) {
                this.recognition.stop();
            }
        },

        async sendTranscript(transcript) {
            await this.GuardaMensaje();
            // this.$q.notify({ type: 'info', message: 'Enviando texto...' });
            
            const base_ = (`${this.token.base}_analitica`).toLowerCase();
            const id = this.token._id;
            const fecha = await this.FechaActual();
            const paciente_id = "";
            const chat_id = this.chat_id;

            this.loadingMensaje = true;
            const data = {
                texto: transcript,
                message_type: "general",
                user_id: id,
                paciente_id: paciente_id,
                chat_id: chat_id,
                database_id: base_,
            };
            console.log(data);
            this.respuesta = "";


            try {
                const response = await fetch('https://api.constanza.deepaxiom.com/chat/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let result = '';

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    result += decoder.decode(value, { stream: true });
                }

                // Limpieza del texto y reconstrucción de palabras
                const cleanText = result
                    .replace(/\n/g, '') // Reemplazar saltos de línea con espacios
                    .replace(/(\w)-\s/g, '$1') // Unir palabras separadas por guiones
                    .replace(/\s+/g, ' ') // Reemplazar múltiples espacios consecutivos por uno
                    .trim();

                console.log('Texto reconstruido:', cleanText); // Texto limpio y unido
                console.log('Texto leído en voz alta.');
                this.respuesta = cleanText;
                await this.reproducirTexto();
            } catch (error) {
                console.error('Error en la petición:', error);
            }

            
        },

        async reproducirTexto() {
            this.isRecording = true;
            const texto = this.respuesta;
            const synth = window.speechSynthesis;
            const voices = await new Promise(resolve => {
                const interval = setInterval(() => {
                    const voicesList = synth.getVoices();
                    if (voicesList.length !== 0) {
                        clearInterval(interval);
                        resolve(voicesList);
                    }
                }, 100);
            });
            // const selectedVoice = voices.find(voice => voice.name.includes('Microsoft Sabina'));
            const selectedVoice = voices.find(voice => voice.name.includes('Microsoft Sabina'));
            // const utterance = new SpeechSynthesisUtterance("Hola buenas tardes, esto es una respuesta automática");
            const utterance = new SpeechSynthesisUtterance(texto);
            // Configurar idioma, tono, velocidad y volumen
            utterance.voice = selectedVoice || voices[0];
            utterance.lang = 'es-ES'; // Español
            utterance.pitch = 1; // Tono normal
            utterance.rate = 1; // Velocidad normal
            utterance.volume = 3; // Volumen máximo

            // Evento al iniciar la reproducción
            utterance.onstart = () => {
                
            };

            // Evento al finalizar la reproducción
            utterance.onend = () => {
                this.isRecording = false;
            };

            // Iniciar la reproducción
            synth.speak(utterance);
        },
    
        async FechaActual() {
            const fechaActual = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
            return fechaActual;
        },

        ModoTexto(){
            this.$emit('cambiarModo')
        },
    
        NuevoChat() {
            this.chat_id = "";
            this.banderaNuevo = true;
        },
    
        async GuardaMensaje() {
            //VALIDAMOS SI YA EXISTE UN ID
            if (!this.banderaNuevo) {
                if (this.chat_id === "") {
                    await this.GetIdChat();
                }
            } else {
                this.banderaNuevo = false;
            }
        },

        async GetIdChat() {
            try {
                const base_ = this.token.base;
                const id = this.token._id;
                let response = await axios.get(`ChatConstanza/GetHistorialChatAsync/${base_}/${id}`);
                const x = response.data;
                if (x) {
                    this.chat_id = x[0]._id;
                }
            } catch (error) {
                console.log(error);
            }
        },
    
        async GetHistorialChat() {
            try {
                const base_ = this.token.base;
                const id = this.token._id;
                let response = await axios.get(`ChatConstanza/GetHistorialChatAsync/${base_}/${id}`);
                const x = response.data;
                if (x) {
                    this.chat_id = x[0]._id;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
    
    
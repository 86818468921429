<template>
    <div class="q-pa-md">
        <q-card class="full-width">
            <q-card-section class="">
                <div class="row">
                    <div class="col text-left">
                        <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close"
                            @click="CierraDialog()" />
                        <span class="text-h6">
                            PDF
                        </span>
                    </div>
                    <div class="col text-right justify-right">

                        <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-whatsapp"
                            @click="PostWhatsApp()" />
                        <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-printer"
                            @click="$refs.pdf.print()" />
                        <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-download"
                            @click="DownloadDocument()" />

                    </div>
                </div>
                <div class="row justify-center">
                    <div class="col-2">
                        <q-input mask="(###) ### - ####" fill-mask dense filled v-model="numTelefono"
                            label="Teléfono" />
                    </div>

                </div>
            </q-card-section>
            <div class="element-linea"></div>
            <q-card-section>
                <div class="row">
                    <div class="col">
                        <div class="text-center">
                            <q-icon name="mdi-minus" size="md" style="cursor: pointer;" color="primary"
                                @click="AnteriorPagina"></q-icon>
                            {{ page }} /{{ numPages }}
                            <q-icon name="mdi-plus" size="md" style="cursor: pointer;" color="primary"
                                @click="SiguientePagina"></q-icon>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div style="width: 100%">
                        <pdf ref="pdf" :src="pdfBase64.base64" :page="page" :rotate="rotate"
                            @progress="loadedRatio = $event" @num-pages="numPages = $event"
                            @link-clicked="page = $event"></pdf>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import pdf from 'vue-pdf';

export default {
    components: {
        pdf: pdf
    },
    data() {
        return {
            loadedRatio: 0,
            page: 1,
            numPages: 0,
            rotate: 0,
            numTelefono: "",

            //DATOS IMAGEN
            datosImagen: null,

            colorDocto: "",
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        pdfBase64() {
            return this.$store.state.pdfStore;
        },

    },

    watch: {

    },

    created() {
        this.numTelefono = this.$store.state.pdfStore.telefono;
    },

    methods: {
        SiguientePagina() {
            let paginas = this.numPages;
            if (this.page < paginas) {
                this.page++;
            } else {
                // this.page = 1;
            }
        },

        AnteriorPagina() {
            let paginas = this.numPages;
            if (this.page > paginas) {
                this.page--;
            } else {
                this.page = 1;
            }
        },

        CierraDialog() {
            this.$emit("CloseDialogPdf");
        },

        async PostWhatsApp() {
            const tipo = this.$store.state.pdfStore.tipo;
            const base64 = this.$store.state.pdfStore.base64;
            const nombreArchivo = this.$store.state.pdfStore.nombreArchivo;
            const telefono = this.numTelefono;
            const nombrePaciente = this.$store.state.pdfStore.nombrePaciente;

            switch (tipo) {
                case "Receta":
                    await this.PostRecetaWhats(base64, nombreArchivo, telefono);
                    break;

                case "Informe medico":
                    await this.PostInformeMedicoWhats(base64, nombreArchivo, telefono, nombrePaciente);
                    break;
            }
        },

        async DownloadDocument() {
            const base64 = this.$store.state.pdfStore.base64;
            const fileName = this.$store.state.pdfStore.nombreArchivo;
            const link = document.createElement("a");
            link.href = base64;
            link.download = fileName;
            link.click();
        },

        async PostRecetaWhats(base64, nombreDocumento, telefono) {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 100, message: 'Enviando...', messageColor: 'white' })
                const objMensaje = {
                    telefono: telefono,
                    base64: base64.split(",")[1],
                    nombreDocumento: nombreDocumento,
                }
                const response = await axios.post(this.$store.state.rutaNotificacionesStore + "WhatsApp/PostMedicamentosPacienteAsync", objMensaje);
                console.log(response.data)
                this.$q.notify({
                    type: 'positive',
                    message: 'Receta enviada con éxito',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.$q.loading.hide()
            } catch (error) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Error al enviar',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async PostInformeMedicoWhats(base64, nombreDocumento, telefono, nombrePaciente) {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 100, message: 'Enviando...', messageColor: 'white' })
                const objMensaje = {
                    telefono: telefono,
                    base64: base64.split(",")[1],
                    nombreDocumento: nombreDocumento,
                    nombrePaciente: nombrePaciente,
                }
                const response = await axios.post(this.$store.state.rutaNotificacionesStore + "WhatsApp/PostNotificacionInformeMedicoAsync", objMensaje);
                console.log(response.data)
                this.$q.notify({
                    type: 'positive',
                    message: 'Informe enviado con éxito',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.$q.loading.hide()
            } catch (error) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Error al enviar',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                console.log(error);
                this.$q.loading.hide()
            }
        },

    }
}
</script>
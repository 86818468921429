<template>
    <q-card>
        <q-dialog v-model="dialogVisible" transition-show="slide-up" transition-hide="slide-down">
            <div class="dialog-container">
                <q-card>
                    <Constanza style="padding:0px;"></Constanza>
                </q-card>
            </div>
        </q-dialog>

        <!-- Diálogo de Quasar -->
        <!-- <q-dialog v-model="dialogVisible" style="padding: 0px;" :style="dialogStyles">
            <Constanza style="padding:0px;"></Constanza>
        </q-dialog> -->

        <q-card-section>
            <q-toolbar>
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close" dense
                    @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Consulta
                </q-toolbar-title>
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" @click="dialogVisible = true">
                    <img src="../../../assets/icono_constanza_logo.png" alt="Icono Constanza"
                        style="width: 30px; height: 30px;" />
                    <q-tooltip>
                        Constanza
                    </q-tooltip>
                </q-btn>
                <q-btn v-if="consulta._id == ''" dense round style="background:#1A4161; color:white"
                    icon="mdi-content-save" class="q-mr-sm" @click="guardarConsulta()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn v-else round dense style="background:#1A4161; color:white" icon="mdi-content-save"
                    class="q-mr-sm" @click="PutConsulta()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>

        <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
            <q-stepper dense alternative-labels v-model="step" header-nav ref="stepper" color="primary" animated>
                <q-step :name="1" :title="titulo1" header-class="q-stepper__title" icon="settings" :done="done1">
                    <DatosGenerales></DatosGenerales>
                </q-step>
                <q-step :name="2" :title="titulo2" icon="create_new_folder" :done="done2">
                    <AnalisisSubjetivo></AnalisisSubjetivo>
                </q-step>
                <q-step :name="3" :title="titulo3" icon="add_comment" :done="done3">
                    <AnalisisObjetivo></AnalisisObjetivo>
                </q-step>
                <q-step :name="4" :title="titulo4" icon="add_comment" :done="done4">
                    <DiagnosticoPlan></DiagnosticoPlan>
                </q-step>
                <!-- <q-step :name="5" :title="titulo5" icon="add_comment" :done="done5">
                    <OrdenLaboratorioImagen></OrdenLaboratorioImagen>
                </q-step> -->
                <q-step :name="6" :title="titulo6" icon="add_comment" :done="done6">
                    <InformeMedico></InformeMedico>
                </q-step>
            </q-stepper>
            <!-- <q-fab direction="right" style="border-radius: 10px; cursor: pointer; width: 60px; "
                class="q-mb-md bg-dark shadow-6 inline-block surface-card efecto efecto-uno fab-custom-icon"
                :disable="draggingFab" v-touch-pan.prevent.mouse="moveFab" hide-icon>
                <Constanza style="padding-right: 300px;"></Constanza>
            </q-fab>
            <q-fab direction="up" style="border-radius: 10px; cursor: pointer; width: 60px; z-index: 5000;"
                class="q-mb-md bg-dark shadow-6 inline-block surface-card efecto efecto-uno fab-custom-icon"
                :disable="draggingFab" v-touch-pan.prevent.mouse="moveFab" hide-icon>
                <Constanza style="padding-right: 300px;"></Constanza>
            </q-fab> -->
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import InformeMedico from './InformeMedico.vue'
import AnalisisObjetivo from './AnalisisObjetivo.vue'
import AnalisisSubjetivo from './AnalisisSubjetivo.vue'
import DatosGenerales from './DatosGenerales.vue'
import DiagnosticoPlan from './DiagnosticoPlan.vue'
import OrdenLaboratorioImagen from './OrdenLaboratorioImagen.vue'
import Constanza from '../../Constanza/Constanza.vue'
export default {
    components: {
        InformeMedico,
        AnalisisObjetivo,
        AnalisisSubjetivo,
        DatosGenerales,
        DiagnosticoPlan,
        Constanza
        // OrdenLaboratorioImagen
    },
    data() {
        return {
            step: 1,
            done1: false,
            done2: false,
            done3: false,
            done4: false,
            done5: false,
            done6: false,

            titulo1: 'Visualización de datos generales',
            titulo2: 'Análisis subjetivo',
            titulo3: 'Análisis objetivo',
            titulo4: 'Diagnóstico y plan',
            titulo5: 'Orden de laboratorio o imagen',
            titulo6: 'Informe médico',
            dialogVisible: false,
        }
    },
    computed: {
        dialogStyles() {
            return {
                position: 'fixed',
                bottom: '16px', // Espaciado desde la parte inferior
                right: '16px', // Espaciado desde la parte derecha
                margin: '0',
                padding: '0',
            };
        },
        token() {
            return this.$store.state.usuario;
        },

        consulta() {
            return this.$store.state.consultaStore;
        },

        fechaElaboracion() {
            moment.locale('es-mx');
            return this.paciente.fechaElaboracion ? moment.utc(this.paciente.fechaElaboracion).format('DD/MMMM/yyyy') : ''
        },

        pacienteConsulta() {
            return this.$store.state.pacienteConsultaStore
        },
    },
    watch: {
    },
    created() {
    },
    methods: {

        async guardarConsulta() {
            if (this.pacienteConsulta.paciente == "") {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un paciente, para registrar su consulta.' })
                return;
            }

            // FECHA CONSULTA
            let fechaC = moment(this.$store.state.consultaStore.fechaConsulta).format('YYYY-MM-DD')
            this.$store.state.consultaStore.fechaConsulta = fechaC;
            this.$store.state.consultaStore.idPaciente = this.pacienteConsulta._id
            this.$store.state.consultaStore.nombrePaciente = this.pacienteConsulta.paciente;

            // FECHA EMISION DEL INFORME
            if (this.$store.state.consultaStore.informeMedico.fechaEmision == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha de emisión del informe' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando consulta. Espere...', messageColor: 'white' })

            try {
                const base_ = this.token.base;
                let response = await axios.post(`Paciente/PostConsulta/${base_}/`, this.consulta)
                this.consulta._id = response.data
                this.$store.state.listaConsultasStore.push(this.consulta)
                this.cierraVentana();
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La consulta ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }

        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async PutConsulta() {
        },
    }
}
</script>
<style scoped>
.dialog-container {
    position: fixed;
    bottom: 16px;
    /* Distancia desde el borde inferior */
    right: 16px;
    /* Distancia desde el borde derecho */
    margin: 0;
    padding: 0;
}
</style>

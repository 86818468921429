import { render, staticRenderFns } from "./AntecedentesAndrologicos.vue?vue&type=template&id=b66da33c"
import script from "./AntecedentesAndrologicos.vue?vue&type=script&lang=js"
export * from "./AntecedentesAndrologicos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QTooltip,QBar,QSpace});

<template>
    <q-layout view="hHh lpR fFf">
        <q-header>
            <q-toolbar class="bg-dark q-px-xl">
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="irInicio" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">One Analítica</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ token.username }}</div>
                <q-btn flat round dense icon="mdi-bell" class="q-mr-md">
                    <q-badge color="red" floating>0</q-badge>
                    <q-menu>

                        <div class="q-pa-xl text-h5 text-center text-grey">
                            Sin Notificaciones
                        </div>
                        <!-- <q-list bordered>
                            <div class="q-pl-md q-pt-md text-h6">Notificaciones</div>
                            <q-item v-for="contact in contacts" :key="contact.id" class="q-my-sm" v-ripple>
                                <q-item-section avatar>
                                    <q-avatar color="primary" text-color="white">
                                        {{ contact.letter }}
                                    </q-avatar>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>{{ contact.name }}</q-item-label>
                                    <q-item-label caption lines="1">{{ contact.email }}</q-item-label>
                                </q-item-section>

                                <q-item-section side>
                                    <q-icon name="chat_bubble" color="green" />
                                </q-item-section>
                            </q-item>

                            <q-separator />
                            <q-item-label header>Offline</q-item-label>
                            <q-item v-for="contact in offline" :key="contact.id" class="q-mb-sm" clickable v-ripple>
                                <q-item-section avatar>
                                    <q-avatar>
                                        <img :src="`https://cdn.quasar.dev/img/${contact.avatar}`">
                                    </q-avatar>
                                </q-item-section>

                                <q-item-section>
                                    <q-item-label>{{ contact.name }}</q-item-label>
                                    <q-item-label caption lines="1">{{ contact.email }}</q-item-label>
                                </q-item-section>

                                <q-item-section side>
                                    <q-icon name="chat_bubble" color="grey" />
                                </q-item-section>
                            </q-item>

                        </q-list> -->
                    </q-menu>
                </q-btn>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer :width="350" v-model="drawerPerfil" behavior="mobile" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>
        <router-view />
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"

export default {

    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
            contacts: [{
                id: 1,
                name: 'Ruddy Jedrzej',
                email: 'rjedrzej0@discuz.net',
                letter: 'R'
            }, {
                id: 2,
                name: 'Mallorie Alessandrini',
                email: 'malessandrini1@marketwatch.com',
                letter: 'M'
            }, {
                id: 3,
                name: 'Elisabetta Wicklen',
                email: 'ewicklen2@microsoft.com',
                letter: 'E'
            }, {
                id: 4,
                name: 'Seka Fawdrey',
                email: 'sfawdrey3@wired.com',
                letter: 'S'
            }],

            offline: [{
                id: 5,
                name: 'Brunhilde Panswick',
                email: 'bpanswick4@csmonitor.com',
                avatar: 'avatar2.jpg'
            }, {
                id: 6,
                name: 'Winfield Stapforth',
                email: 'wstapforth5@pcworld.com',
                avatar: 'avatar6.jpg'
            }]
        }
    },
    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    // created() {
    //     this.$store.dispatch("autoLogin");
    // },
    methods: {
        irInicio() {
            this.$router.push({ name: 'Home' })
        }
    }
}
</script>
<style>
.fondo {
    background-image: url('../../assets/fondo-14.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    margin: 0;
}
</style>
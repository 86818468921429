<template>
    <div class="row q-col-gutter-sm">

        <!-- ESTUDIOS PREVIOS -->
        <q-dialog v-model="dialogEstudios" persistent>
            <q-card style="min-width: 1300px;">
                <q-bar class="bg-primary text-white">
                    <div>PRUEBAS DE LABORATORIO E IMAGEN</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12">
                            <q-select v-model="tipoEstudio"
                                :options="['Estudio Imagenológico', 'Estudio Laboratorio', 'Pruebas Moleculares', 'Anatomía Patológica']"
                                dense filled label="Tipo de Estudio" />
                        </div>
                        <template v-if="tipoEstudio == 'Estudio Imagenológico'">
                            <div class="col-12">
                                <q-select new-value-mode="add" v-model="estudioImagenologico.estudio" emit-value
                                    map-options @filter="filtroImagenologico" :options="itemsfiltroImagenologico"
                                    use-input input-debounce="0" dense filled label="Estudio Imagenológico"
                                    virtual-scroll-slice-size="1" />
                            </div>
                        </template>
                        <template v-if="tipoEstudio == 'Estudio Laboratorio'">
                            <div class="col-12">
                                <q-select new-value-mode="add" v-model="estudioLaboratorio.estudio" emit-value
                                    map-options @filter="filtroLaboratorio" :options="itemsfiltroLaboratorio" use-input
                                    input-debounce="0" dense filled label="Estudio Laboratorio"
                                    virtual-scroll-slice-size="1" />
                            </div>
                        </template>
                        <template v-if="tipoEstudio == 'Pruebas Moleculares'">
                            <div class="col-12">
                                <q-select new-value-mode="add" v-model="pruebasMoleculares.estudio" emit-value
                                    map-options @filter="filtroPruebas" :options="itemsfiltroPruebas" use-input
                                    input-debounce="0" dense filled label="Pruebas Moleculares"
                                    virtual-scroll-slice-size="1" />
                            </div>
                        </template>
                        <template v-if="tipoEstudio == 'Anatomía Patológica'">
                            <div class="col-12">
                                <q-select new-value-mode="add" v-model="anatomiaPatologica.estudio" emit-value
                                    map-options @filter="filtroAnatomias" :options="itemsfiltroAnatomias" use-input
                                    input-debounce="0" dense filled label="Anatomía Patológica"
                                    virtual-scroll-slice-size="1" />
                            </div>
                        </template>
                    </div>
                </q-card-section>
                <q-card-actions v-if="tipoEstudio == 'Estudio Imagenológico'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio1()" />
                </q-card-actions>
                <q-card-actions v-if="tipoEstudio == 'Estudio Laboratorio'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio2()" />
                </q-card-actions>
                <q-card-actions v-if="tipoEstudio == 'Pruebas Moleculares'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio3()" />
                </q-card-actions>
                <q-card-actions v-if="tipoEstudio == 'Anatomía Patológica'" align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEstudio4()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA MOSTRAR LOS PDF -->
        <q-dialog full-width v-model="dialogVisor" persistent transition-show="scale" transition-hide="scale">
            <VisorPdf @CloseDialogPdf="dialogVisor = false"></VisorPdf>
        </q-dialog>


        <div class="col-12 q-mt-lg q-mb-lg  row">
            <div class="doc-h2">Orden de Laboratorio o Imagen</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos q-mr-md" icon="mdi-printer" unelevated rounded
                style="background:#1A4161; color:white" label="Imprimir" @click="GetOrden()" />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar estudios" @click="dialogEstudios = true" />
            <div class="element-linea"></div>
        </div>


        <!-- <div class="col-12 col-md-6">
            <q-input readonly v-model="pacienteConsulta.paciente" dense filled label="Nombre (s) del paciente" />
        </div>
        <div class="col-12 col-md-3">
            <q-input readonly v-model="pacienteConsulta.genero" dense filled label="Genero" />
        </div>
        <div class="col-12 col-md-3">
            <q-input readonly dense v-model="pacienteConsulta.fechaNacimiento" label="Fecha de Nacimiento" mask="date"
                bottom-slots placeholder="yyyy/MM/dd" filled>
                <template v-slot:after>
                    <q-icon name="event" class="cursor-pointer">
                    </q-icon>
                </template>
            </q-input>
        </div> -->
        <div class="col-12   row">
            <div class="col-12 col-md-3">
                <q-input dense v-model="fechaEmision" label="Fecha de Emisión" mask="date" bottom-slots
                    placeholder="yyyy/MM/dd" filled>
                    <template v-slot:after>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="fechaEmision">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>
        </div>
        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="consulta.ordenLaboratorioImagen" :columns="columns" :rows-per-page-options="[0]" separator="cell"
                no-data-label="Sin datos disponibles" row-key="sitio">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarEstudio(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipo" :props="props">{{ props.row.tipo }}
                        </q-td>
                        <q-td key="estudio" :props="props">
                            {{ props.row.estudio }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
import { date } from 'quasar'
import { PdfOrdenLaboratorioImagen } from '../../Pdf/PdfOrdenLaboratorioImagen.js';
import VisorPdf from '../../Pdf/VisorPdf.vue'
export default {
    components: {
        VisorPdf
    },
    data() {
        return {
            itemsfiltroImagenologico: null,
            itemsfiltroPruebas: null,
            itemsfiltroLaboratorio: null,
            itemsfiltroAnatomias: null,

            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo de estudio', field: 'tipo', sortable: true },
                { name: 'estudio', align: 'center', label: 'Estudio', field: 'estudio', sortable: true, },
            ],
            dialogEstudios: false,
            tipoEstudio: 'Estudio Imagenológico',
            estudioImagenologico: {
                tipo: '',
                estudio: '',
            },
            estudioLaboratorio: {
                tipo: '',
                estudio: '',
            },
            pruebasMoleculares: {
                tipo: '',
                estudio: '',
            },
            anatomiaPatologica: {
                tipo: '',
                estudio: '',
            },
            dialogVisor: false,
            fechaEmision: new Date()
        }
    },
    computed: {
        consulta() {
            return this.$store.state.consultaStore;
        },
        pacienteConsulta() {
            return this.$store.state.pacienteConsultaStore
        },
        token() {
            return this.$store.state.usuario;
        },
        itemsEstudiosImagenologicos() {
            return this.$store.state.listaCatalogoEstudiosImagenologicos
        },
        itemsEstudiosLaboratorio() {
            return this.$store.state.listaCatalogoEstudiosLaboratorio
        },
        itemsEstudiosMoleculares() {
            return this.$store.state.listaCatalogoEstudiosMoleculares
        },
        itemsAnatomiaPatologica() {
            return this.$store.state.listaCatalogoEstudiosAnatomiaPatologica
        },
        fecha1() {
            moment.locale('es-mx');
            return this.fechaEmision
                ? moment.utc(this.fechaEmision).format('DD/MMMM/yyyy')
                : moment().format('DD/MMMM/yyyy');
        },
    },
    watch: {
    },

    created() {
    },

    methods: {
        filtroImagenologico(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroImagenologico = this.itemsEstudiosImagenologicos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroImagenologico.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroLaboratorio(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroLaboratorio = this.itemsEstudiosLaboratorio.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroLaboratorio.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroPruebas(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPruebas = this.itemsEstudiosMoleculares.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPruebas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroAnatomias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAnatomias = this.itemsAnatomiaPatologica.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAnatomias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        agregarEstudio1() {
            this.estudioImagenologico.tipo = this.tipoEstudio

            this.$store.state.consultaStore.ordenLaboratorioImagen.push(this.estudioImagenologico);
            this.estudioImagenologico = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        agregarEstudio2() {
            this.estudioLaboratorio.tipo = this.tipoEstudio

            this.$store.state.consultaStore.ordenLaboratorioImagen.push(this.estudioLaboratorio);
            this.estudioLaboratorio = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        agregarEstudio3() {
            this.pruebasMoleculares.tipo = this.tipoEstudio

            this.$store.state.consultaStore.ordenLaboratorioImagen.push(this.pruebasMoleculares);
            this.pruebasMoleculares = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        agregarEstudio4() {
            this.anatomiaPatologica.tipo = this.tipoEstudio

            this.$store.state.consultaStore.ordenLaboratorioImagen.push(this.anatomiaPatologica);
            this.anatomiaPatologica = {
                tipo: '',
                base64: '',
                estudio: '',
                fecha: '',
                nombreArchivo: '',
                tipoArchivo: ''
            }
        },
        eliminarEstudio(data) {
            let editedIndex = this.$store.state.consultaStore.ordenLaboratorioImagen.indexOf(data)
            this.$store.state.consultaStore.ordenLaboratorioImagen.splice(editedIndex, 1)
        },

        async GetOrden() {
            const idPaciente = this.pacienteConsulta._id

            if (!this.pacienteConsulta.celular) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            if (this.pacienteConsulta.celular === "") {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 100, message: 'Consultando datos. Espere...', messageColor: 'white' })
                // const _id = item._id;
                // const base_ = this.token.base;
                // let response = await axios.get(`Paciente/GetInformeMedicoIdConsultaAsync/${base_}/${_id}`);
                const objPaciente = this.pacienteConsulta
                const objInforme = this.consulta.ordenLaboratorioImagen
                const nombreArchivo = idPaciente + ".pdf";
                const tipoHoja = 1;

                //CONSULTAMOS LA CONFIGURACION DE LOS DOCUMENTOS
                const ObjConfig = await this.GetConfiguracionDocumentos();
                const ObjPerfil = await this.GetPerfilMedico();
                const diagnosticos = this.$store.state.consultaStore.diagnosticoPlan.diagnosticos;

                if (!ObjConfig) {
                    this.$q.notify({
                        type: 'warning',
                        message: 'Indique los campos de "Configuración de documentos" para generar el documento, ubicado en el apartado de configuracion en el submenu superior derecho',
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                    this.$q.loading.hide()
                    return;
                }

                if (!ObjPerfil) {
                    this.$q.notify({
                        type: 'warning',
                        message: 'Indique los campos de "Perfil médico" para generar el documento, ubicado en el apartado de configuracion en el submenu superior derecho',
                        actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                    })
                    this.$q.loading.hide()
                    return;
                }
                this.$q.loading.hide()

                let base64 = await PdfOrdenLaboratorioImagen(this.fechaEmision, diagnosticos, objPaciente, objInforme, nombreArchivo, tipoHoja, ObjConfig, ObjPerfil);
                console.log(base64)
                //VISTA PREVIA DEL DOCUMENTO
                let objPdf = {
                    base64: base64,
                    nombreArchivo: idPaciente + ".pdf",
                    tipo: "Orden de Laboratorio o Imagen",
                    telefono: objPaciente.celular,
                    nombrePaciente: objPaciente.nombre + " " + objPaciente.apellidos
                }
                this.$store.state.pdfStore = { ...objPdf };
                this.dialogVisor = true;

                this.$q.loading.hide()
            } catch (error) {
                this.$q.loading.hide()

                console.log(error)
                this.$q.loading.hide()
            }
            this.$q.loading.hide()
        },

        async GetConfiguracionDocumentos() {
            try {
                const base_ = this.token.base;
                const id = this.token._id
                let response = await axios.get(`Configuracion/GetConfiguracionDocumentosAsync/${base_}/${id}`);
                let x = { ...response.data }
                return x;
            } catch (error) {
                console.log(error);
            }
        },

        async GetPerfilMedico() {
            try {
                const base_ = this.token.base;
                const id = this.token._id
                let response = await axios.get(`Configuracion/GetPerfilMedicoAsync/${base_}/${id}`);
                let x = { ...response.data }
                return x;
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>
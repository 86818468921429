// src/utils/indexedDBHelper.js

// Abre la base de datos o la crea si no existe
export const openDB = (dbName, version) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, version);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("catalogos")) {
                db.createObjectStore("catalogos", { keyPath: "nombre" }); // "nombre" como clave primaria
            }
        };
        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(event.target.error);
    });
};

// Guarda un catálogo en IndexedDB
export const saveCatalogo = (db, nombre, data) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction("catalogos", "readwrite");
        const store = transaction.objectStore("catalogos");
        store.put({ nombre, data });
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) => reject(event.target.error);
    });
};

// Recupera un catálogo desde IndexedDB
export const getCatalogo = (db, nombre) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction("catalogos", "readonly");
        const store = transaction.objectStore("catalogos");
        const request = store.get(nombre);
        request.onsuccess = () => resolve(request.result ? request.result.data : null);
        request.onerror = (event) => reject(event.target.error);
    });
};

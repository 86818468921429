<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <router-view />
      <q-page-sticky v-if="token" position="bottom-right" :offset="fabPos">
        <!-- <q-btn fab color="primary" class="q-mb-md" @click="dialogConstanza = true" :disable="draggingFab"
          v-touch-pan.prevent.mouse="moveFab">
          <q-icon style="color: aliceblue;font-size: 2em;">
            <img src=".\assets\icono_constanza_blanco.png" style="width: 50px;" alt="Icon" />
          </q-icon>
          <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
            :offset="[10, 10]">Constanza</q-tooltip>
        </q-btn> -->
        <!-- <q-fab direction="up" style="border-radius: 10px; cursor: pointer; width: 60px; z-index: 0;"
          class="q-mb-md bg-dark shadow-6 inline-block surface-card efecto efecto-uno fab-custom-icon"
          :disable="draggingFab" v-touch-pan.prevent.mouse="moveFab" hide-icon>
          <Constanza style="padding-right: 300px;"></Constanza>
        </q-fab> -->
        <q-fab direction="up" style="border-radius: 10px; cursor: pointer; width: 60px; z-index: 3000;"
          class="q-mb-md bg-dark shadow-6 inline-block surface-card efecto efecto-uno fab-custom-icon"
          :disable="draggingFab" v-touch-pan.prevent.mouse="moveFab" hide-icon>
          <Constanza style="padding-right: 300px;"></Constanza>
        </q-fab>
      </q-page-sticky>
    </q-page-container>
  </q-layout>
</template>

<script>
//import Inicio from "./views/Home.vue"
import Inicio from "./components/Main/MainPage.vue"
import axios from 'axios'
import { colors } from 'quasar'
import { QSpinnerCube } from 'quasar';
import Constanza from './components/Constanza/Constanza.vue'
import { openDB, saveCatalogo, getCatalogo } from './utils/indexedDBHelper';
export default {
  name: 'LayoutDefault',

  components: {
    Constanza
  },
  data() {

    return {
      leftDrawerOpen: false,
      dialogConstanza: false,
      fabPos: [18, 18],
      draggingFab: false,
      iconConstanza: require('@/assets/icono_constanza_blanco.png'),

      ListaCatalogos: [
        { catalogo: 'aseguradoras', ruta: 'Catalogos/GetCatalogoAseguradoras/', store: 'listaCatalogoAseguradoras' },
        { catalogo: 'pasi', ruta: 'Catalogos/GetCatalogoPais/', store: 'listaCatalogoPaises' },
        { catalogo: 'diagnosticosCIE', ruta: 'Catalogos/GetCatalogoDiagnosticoString/', store: 'listaCatalogoDiagnosticosCIE' },
        { catalogo: 'familiares', ruta: 'Catalogos/GetCatalogoFamiliares/', store: 'listaCatalogoFamiliares' },
        { catalogo: 'estados', ruta: 'Catalogos/GetCatalogoEstados/', store: 'listaCatalogoEstados' },
        { catalogo: 'municipios', ruta: 'Catalogos/GetCatalogoMunicipios/', store: 'listaCatalogoMunicipios' },
        { catalogo: 'religiones', ruta: 'Catalogos/GetCatalogoReligiones/', store: 'listaCatalogoReligiones' },
        { catalogo: 'ocupaciones', ruta: 'Catalogos/GetCatalogoOcupaciones/', store: 'listaCatalogoOcupaciones' },
        { catalogo: 'estadosCiviles', ruta: 'Catalogos/GetCatalogoEstadosCiviles/', store: 'listaCatalogoEstadosCiviles' },
        { catalogo: 'escolaridad', ruta: 'Catalogos/GetCatalogoEscolaridad/', store: 'listaCatalogoEscolaridad' },
        { catalogo: 'animales', ruta: 'Catalogos/GetCatalogoAnimales/', store: 'listaCatalogoAnimales' },
        { catalogo: 'zoonosis', ruta: 'Catalogos/GetCatalogoZoonosis/', store: 'listaCatalogoZoonosis' },
        { catalogo: 'actividadesFisicas', ruta: 'Catalogos/GetCatalogoActividadesFisicas/', store: 'listaCatalogoActividadesFisicas' },
        { catalogo: 'intoleranciaAlimentos', ruta: 'Catalogos/GetCatalogoIntoleranciaAlimentos/', store: 'listaCatalogoIntolerancias' },
        { catalogo: 'alergias', ruta: 'Catalogos/GetCatalogoAlergias/', store: 'listaCatalogoAlergias' },
        { catalogo: 'hemotipos', ruta: 'Catalogos/GetCatalogoHemotipos/', store: 'listaCatalogoHemotipos' },
        { catalogo: 'bebidasAlcoholicas', ruta: 'Catalogos/GetCatalogoBebidasAlcoholicas/', store: 'listaCatalogoBebidas' },
        { catalogo: 'toxicomanias', ruta: 'Catalogos/GetCatalogoToxicomanias/', store: 'listaCatalogoToxicomanias' },
        { catalogo: 'enfermedadesCronicas', ruta: 'Catalogos/GetCatalogoEnfermedadesCronicas/', store: 'listaCatalogoEnfermedadesCronicas' },
        { catalogo: 'tratamientos', ruta: 'Catalogos/GetCatalogoTratamientos/', store: 'listaCatalogoTratamientos' },
        { catalogo: 'procedimientosQuirurgicos', ruta: 'Catalogos/GetCatalogoProcedimientosQuirurgicos/', store: 'listaCatalogoProcedimientos' },
        { catalogo: 'traumatismos', ruta: 'Catalogos/GetCatalogoTraumatismos/', store: 'listaCatalogoTraumatismos' },
        { catalogo: 'huesos', ruta: 'Catalogos/GetCatalogoHuesos/', store: 'listaCatalogoHuesos' },
        { catalogo: 'articulaciones', ruta: 'Catalogos/GetCatalogoArticulaciones/', store: 'listaCatalogoArticulaciones' },
        { catalogo: 'ets', ruta: 'Catalogos/GetCatalogoETS/', store: 'listaCatalogoETS' },
        { catalogo: 'mpf', ruta: 'Catalogos/GetCatalogoMPF/', store: 'listaCatalogoMPF' },
        { catalogo: 'radioterapias', ruta: 'Catalogos/GetCatalogoRadioterapias/', store: 'listaCatalogoRadioterapias' },
        { catalogo: 'terapiasSistemicas', ruta: 'Catalogos/GetCatalogoTerapiasSistemicas/', store: 'listaCatalogoTerapias' },
        { catalogo: 'sintomas', ruta: 'Catalogos/GetCatalogoSintomas/', store: 'listaCatalogoSintomas' },
        { catalogo: 'partesCuerpo', ruta: 'Catalogos/GetCatalogoPartesCuerpo/', store: 'listaCatalogoPartesCuerpo' },
        { catalogo: 'sitiosPrimarios', ruta: 'Catalogos/GetCatalogoSitiosPrimarios/', store: 'listaCatalogoSitiosPrimarios' },
        { catalogo: 'anatomiaPatologica', ruta: 'Catalogos/GetCatalogoEstudiosAnatomiaPatologica/', store: 'listaCatalogoEstudiosAnatomiaPatologica' },
        { catalogo: 'pronosticos', ruta: 'Catalogos/GetCatalogoPronosticos/', store: 'listaCatalogoPronosticos' },
        { catalogo: 'motivoTransfusion', ruta: 'Catalogos/GetCatalogoMotivoTransfusion/', store: 'listaCatalogoMotivosTransfusion' },
        { catalogo: 'tipoReaacionAdversa', ruta: 'Catalogos/GetCatalogoTipoReaccionAdversa/', store: 'listaCatalogoTipoReaccionAdversa' },
        { catalogo: 'tipoTransfusion', ruta: 'Catalogos/GetCatalogoTipoTransfusion/', store: 'listaCatalogoTipoTransfusion' },
        { catalogo: 'sintomasNeurologicas', ruta: 'Catalogos/GetCatalogoSintomasNeurologicos/', store: 'listaCatalogoNeurologicos' },
        { catalogo: 'sintomasCabezaCuello', ruta: 'Catalogos/GetCatalogoSintomasCabezaCuello/', store: 'listaCatalogoCabezaCuello' },
        { catalogo: 'sintomasTorazEspalda', ruta: 'Catalogos/GetCatalogoSintomasToraxEspalda/', store: 'listaCatalogoToraxEspalda' },
        { catalogo: 'sintomasAbdomenPelvis', ruta: 'Catalogos/GetCatalogoSintomasAbdomenPelvis/', store: 'listaCatalogoAbdomenPelvis' },
        { catalogo: 'sintomasExtremidades', ruta: 'Catalogos/GetCatalogoSintomasExtremidades/', store: 'listaCatalogoExtremidades' },
        { catalogo: 'sintomasTegumemntos', ruta: 'Catalogos/GetCatalogoSintomasTegumemntos/', store: 'listaCatalogoTegumentos' },
        { catalogo: 'estudiosImagenologicos', ruta: 'Catalogos/GetCatalogoEstudiosImagenologicos/', store: 'listaCatalogoEstudiosImagenologicos' },
        { catalogo: 'estudiosLaboratorio', ruta: 'Catalogos/GetCatalogoEstudiosLaboratorio/', store: 'listaCatalogoEstudiosLaboratorio' },
        { catalogo: 'estudiosMoleculares', ruta: 'Catalogos/GetCatalogoEstudiosMoleculares/', store: 'listaCatalogoEstudiosMoleculares' },
        { catalogo: 'medicamentosObj', ruta: 'Catalogos/GetCatalogoMedicamamentosObjeto/', store: 'listaCatalogoMedicamentosObj' },
        { catalogo: 'diagnosticoString', ruta: 'Catalogos/GetCatalogoDiagnosticoString/', store: 'listaCatalogoDiagnosticosString' },
        { catalogo: 'serviciosMedicos', ruta: 'Catalogos/GetCatalogoServiciosMedicos/', store: 'listaCatalogoServciosMedicos' },
        { catalogo: 'motivosConsulta', ruta: 'Catalogos/GetCatalogoMotivosConsulta/', store: 'listaCatalogoMotivosConsulta' },
        { catalogo: 'estadioDiagnostico', ruta: 'Catalogos/GetCatalogoEstadioDiagnostico/', store: 'listaCatalogoEstadioDiagnostico' },
        { catalogo: 'frecuenciaAdministracion', ruta: 'Catalogos/GetCatalogoFrecuenciaAdministracion/', store: 'listaCatalogoFrecuenciaAdministracion' },
        { catalogo: 'duracionTratamiento', ruta: 'Catalogos/GetCatalogoDuracionTratamiento/', store: 'listaCatalogoDuracionTratamiento' },
        { catalogo: 'viasAdministracion', ruta: 'Catalogos/GetCatalogoViasAdministracion/', store: 'listaCatalogoViasAdministracion' },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  created() {
    // CATALOGOS PACIENTES
    this.$store.dispatch("autoLogin");
    this.$q.dark.set(this.$store.state.isDark);
    // this.Iniciales();
    this.cargarTodosLosCatalogos();
  },
  methods: {
    async cargarTodosLosCatalogos() {
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Estamos preparando todo. Espere...', messageColor: 'white' })
      for (const item of this.ListaCatalogos) {
        await this.cargarCatalogo(item.catalogo, item.ruta, item.store);
      }
      this.$q.loading.hide()
    },

    async cargarCatalogo(nombre, apiEndpoint, storeKey) {
      try {
        if (this.$store.state[storeKey]?.length) {
          return;
        }

        const db = await openDB("MiBaseDeDatos", 1);

        const catalogo = await getCatalogo(db, nombre);

        if (catalogo) {
          // console.log(`Cargando ${nombre} desde IndexedDB`);
          this.$store.commit("setCatalogo", { key: storeKey, value: catalogo });
        } else {
          // console.log(`Consultando API para ${nombre}`);
          const response = await axios.get(apiEndpoint);
          const data = response.data;
          await saveCatalogo(db, nombre, data);

          this.$store.commit("setCatalogo", { key: storeKey, value: data });
        }
      } catch (error) {
        console.error(`Error al cargar el catálogo ${nombre}:`, error);
      }
    },

    async Iniciales() {
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Estamos preparando todo. Espere...', messageColor: 'white' })
      const tareas1 = [
        this.getCatalogoAseguradoras(),
        this.getCatalogoPais(),
        // this.getCatalogoDiagnosticosCIE(),
        // this.getCatalogoFamiliares(),
        // this.getCatalogoEstados(),
        // this.getCatalogoMunicipios(),
        // this.getCatalogoEstadosCiviles(),
        // this.getCatalogoOcupaciones(),
        // this.getCatalogoReligiones(),
        // this.getCatalogoEscolsridad(),
        // this.GetCatalogoToxicomanias(),
        // this.GetCatalogoBebidasAlcoholicas(),
        // this.GetCatalogoHemotipos(),
        // this.GetCatalogoAlergias(),
        // this.GetCatalogoIntoleranciaAlimentos(),
        // this.GetCatalogoActividadesFisicas(),
        // this.GetCatalogoZoonosis(),
        // this.GetCatalogoAnimales(),
        // this.GetCatalogoEnfermedadesCronicas(),
        // this.GetCatalogoArticulaciones(),
        // this.GetCatalogoHuesos(),
        // this.GetCatalogoProcedimientosQuirurgicos(),
        // this.GetCatalogoTraumatismos(),
        // this.GetCatalogoTratamientos(),
        // this.GetCatalogoETS(),
        // this.GetCatalogoMPF(),
        // this.GetCatalogoRadioterapias(),
        // this.GetCatalogoTerapiasSistemicas(),
        // this.GetCatalogoSintomas(),
        // this.GetCatalogoPartesCuerpo(),
        // this.GetCatalogoSitiosPrimarios(),
        // this.GetCatalogoEstudiosAnatomiaPatologica(),
        // this.GetCatalogoPronosticos(),
        // this.GetCatalogoMotivoTransfusion(),
        // this.GetCatalogoTipoReaccionAdversa(),
        // this.GetCatalogoTipoTransfusion(),
      ];
      const paso1 = await Promise.all(tareas1);

      // CATALOGOS CONSULTAS
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Un poco más...', messageColor: 'white' })
      const tareas2 = [
        // this.GetCatalogoSintomasAbdomenPelvis(),
        // this.GetCatalogoSintomasCabezaCuello(),
        // this.GetCatalogoSintomasExtremidades(),
        // this.GetCatalogoSintomasNeurologicos(),
        // this.GetCatalogoSintomasTegumemntos(),
        // this.GetCatalogoSintomasToraxEspalda(),
        // this.GetCatalogoPartesCuerpo(),
        // this.GetCatalogoEstudiosImagenologicos(),
        // this.GetCatalogoEstudiosLaboratorio(),
        // this.GetCatalogoEstudiosMoleculares(),
        // this.GetCatalogoMedicamamentosObjeto(),
        // this.GetCatalogoDiagnosticoString(),
        // this.GetCatalogoServiciosMedicos(),
        // this.GetCatalogoEstadioDiagnostico(),
        // this.GetCatalogoMotivosConsulta(),
        // this.GetCatalogoViasAdministracion(),
        // this.GetCatalogoDuracionTratamiento(),
        // this.GetCatalogoFrecuenciaAdministracion(),
        // this.GetCatalogoEstudiosAnatomiaPatologica(),


      ];
      const paso2 = await Promise.all(tareas2);

      this.$q.loading.hide()
    },

    // GET CATALOGOS PACIENTES
    async getCatalogoAseguradoras() {
      console.log(this.$store.state.listaCatalogoAseguradoras.length)
      if (this.$store.state.listaCatalogoAseguradoras.length !== 0) {
        return;
      }
      try {
        const db = await openDB("MiBaseDeDatos", 1);
        const catalogo = await getCatalogo(db, "aseguradoras");

        if (catalogo) {
          console.log("Cargando aseguradoras desde IndexedDB");
          this.$store.state.listaCatalogoAseguradoras = catalogo;
          console.log(this.$store.state.listaCatalogoAseguradoras.length)
        } else {
          console.log(this.$store.state.listaCatalogoAseguradoras.length)
          console.log("Consultando API para aseguradoras");
          const response = await axios.get("Catalogos/GetCatalogoAseguradoras/");
          const catalogo = response.data;
          await saveCatalogo(db, "aseguradoras", catalogo);
          this.$store.state.listaCatalogoAseguradoras = catalogo;
        }
      } catch (error) {
        console.error("Error al obtener el catálogo de aseguradoras:", error);
      }
    },

    async getCatalogoPais() {
      if (this.$store.state.listaCatalogoPaises.length !== 0) {
        return;
      }
      try {
        const db = await openDB("MiBaseDeDatos", 1);
        const catalogo = await getCatalogo(db, "paises");

        if (catalogo) {
          console.log("Cargando países desde IndexedDB");
          this.$store.state.listaCatalogoPaises = catalogo;
        } else {
          console.log("Consultando API para países");
          const response = await axios.get("Catalogos/GetCatalogoPais/");
          const catalogo = response.data;
          await saveCatalogo(db, "paises", catalogo);
          this.$store.state.listaCatalogoPaises = catalogo;
        }
      } catch (error) {
        console.error("Error al obtener el catálogo de países:", error);
      }
    },

    // async getCatalogoAseguradoras() {
    //   if (this.$store.state.listaCatalogoAseguradoras.length != 0) {
    //     return
    //   }
    //   try {
    //     let response = await axios.get("Catalogos/GetCatalogoAseguradoras/");
    //     let catalogo = response.data;
    //     this.$store.state.listaCatalogoAseguradoras = catalogo;

    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    // async getCatalogoPais() {
    //   if (this.$store.state.listaCatalogoPaises.length != 0) {
    //     return
    //   }
    //   try {
    //     let response = await axios.get("Catalogos/GetCatalogoPais/");
    //     let catalogo = response.data;
    //     this.$store.state.listaCatalogoPaises = catalogo;

    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    async getCatalogoDiagnosticosCIE() {
      if (this.$store.state.listaCatalogoDiagnosticosCIE.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoDiagnosticoString/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoDiagnosticosCIE = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoFamiliares() {
      if (this.$store.state.listaCatalogoFamiliares.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoFamiliares/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoFamiliares = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoEstados() {
      if (this.$store.state.listaCatalogoEstados.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstados/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstados = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoMunicipios() {
      if (this.$store.state.listaCatalogoMunicipios.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMunicipios/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMunicipios = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoReligiones() {
      if (this.$store.state.listaCatalogoReligiones.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoReligiones/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoReligiones = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoOcupaciones() {
      if (this.$store.state.listaCatalogoOcupaciones.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoOcupaciones/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoOcupaciones = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoEstadosCiviles() {
      if (this.$store.state.listaCatalogoEstadosCiviles.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstadosCiviles/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstadosCiviles = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoEscolsridad() {
      if (this.$store.state.listaCatalogoEscolaridad.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEscolaridad/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEscolaridad = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoAnimales() {
      if (this.$store.state.listaCatalogoAnimales.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoAnimales/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoAnimales = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoZoonosis() {
      if (this.$store.state.listaCatalogoZoonosis.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoZoonosis/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoZoonosis = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoActividadesFisicas() {
      if (this.$store.state.listaCatalogoActividadesFisicas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoActividadesFisicas/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoActividadesFisicas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoIntoleranciaAlimentos() {
      if (this.$store.state.listaCatalogoIntolerancias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoIntoleranciaAlimentos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoIntolerancias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoAlergias() {
      if (this.$store.state.listaCatalogoAlergias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoAlergias/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoAlergias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoHemotipos() {
      if (this.$store.state.listaCatalogoHemotipos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoHemotipos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoHemotipos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoBebidasAlcoholicas() {
      if (this.$store.state.listaCatalogoBebidas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoBebidasAlcoholicas/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoBebidas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoToxicomanias() {
      if (this.$store.state.listaCatalogoToxicomanias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoToxicomanias/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoToxicomanias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEnfermedadesCronicas() {
      if (this.$store.state.listaCatalogoEnfermedadesCronicas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEnfermedadesCronicas/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEnfermedadesCronicas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoTratamientos() {
      if (this.$store.state.listaCatalogoTratamientos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTratamientos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTratamientos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoProcedimientosQuirurgicos() {
      if (this.$store.state.listaCatalogoProcedimientos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoProcedimientosQuirurgicos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoProcedimientos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoTraumatismos() {
      if (this.$store.state.listaCatalogoTraumatismos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTraumatismos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTraumatismos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoHuesos() {
      if (this.$store.state.listaCatalogoHuesos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoHuesos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoHuesos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoArticulaciones() {
      if (this.$store.state.listaCatalogoArticulaciones.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoArticulaciones/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoArticulaciones = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoETS() {
      if (this.$store.state.listaCatalogoETS.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoETS/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoETS = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoMPF() {
      if (this.$store.state.listaCatalogoMPF.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMPF/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMPF = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoRadioterapias() {
      if (this.$store.state.listaCatalogoRadioterapias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoRadioterapias/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoRadioterapias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoTerapiasSistemicas() {
      if (this.$store.state.listaCatalogoTerapias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTerapiasSistemicas/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTerapias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomas() {
      if (this.$store.state.listaCatalogoSintomas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomas/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoSintomas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoPartesCuerpo() {
      if (this.$store.state.listaCatalogoPartesCuerpo.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoPartesCuerpo/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoPartesCuerpo = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSitiosPrimarios() {
      if (this.$store.state.listaCatalogoSitiosPrimarios.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSitiosPrimarios/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoSitiosPrimarios = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstudiosAnatomiaPatologica() {
      if (this.$store.state.listaCatalogoEstudiosAnatomiaPatologica.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstudiosAnatomiaPatologica/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstudiosAnatomiaPatologica = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoPronosticos() {
      if (this.$store.state.listaCatalogoPronosticos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoPronosticos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoPronosticos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoMotivoTransfusion() {
      if (this.$store.state.listaCatalogoMotivosTransfusion.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMotivoTransfusion/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMotivosTransfusion = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoTipoReaccionAdversa() {
      if (this.$store.state.listaCatalogoTipoReaccionAdversa.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTipoReaccionAdversa/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTipoReaccionAdversa = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoTipoTransfusion() {
      if (this.$store.state.listaCatalogoTipoTransfusion.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTipoTransfusion/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTipoTransfusion = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    // GET CONSULTAS 
    async GetCatalogoSintomasNeurologicos() {
      if (this.$store.state.listaCatalogoNeurologicos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasNeurologicos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoNeurologicos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasCabezaCuello() {
      if (this.$store.state.listaCatalogoCabezaCuello.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasCabezaCuello/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoCabezaCuello = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasToraxEspalda() {
      if (this.$store.state.listaCatalogoToraxEspalda.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasToraxEspalda/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoToraxEspalda = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasAbdomenPelvis() {
      if (this.$store.state.listaCatalogoAbdomenPelvis.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasAbdomenPelvis/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoAbdomenPelvis = catalogo;
      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasExtremidades() {
      if (this.$store.state.listaCatalogoExtremidades.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasExtremidades/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoExtremidades = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasTegumemntos() {
      if (this.$store.state.listaCatalogoTegumentos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasTegumemntos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTegumentos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoPartesCuerpo() {
      if (this.$store.state.listaCatalogoPartesCuerpo.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoPartesCuerpo/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoPartesCuerpo = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstudiosImagenologicos() {
      if (this.$store.state.listaCatalogoEstudiosImagenologicos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstudiosImagenologicos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstudiosImagenologicos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstudiosLaboratorio() {
      if (this.$store.state.listaCatalogoEstudiosLaboratorio.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstudiosLaboratorio/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstudiosLaboratorio = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstudiosMoleculares() {
      if (this.$store.state.listaCatalogoEstudiosMoleculares.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstudiosMoleculares/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstudiosMoleculares = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoMedicamamentosObjeto() {
      if (this.$store.state.listaCatalogoMedicamentosObj.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMedicamamentosObjeto/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMedicamentosObj = catalogo;
        // console.log(catalogo);
      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoDiagnosticoString() {
      if (this.$store.state.listaCatalogoDiagnosticosString.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoDiagnosticoString/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoDiagnosticosString = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoServiciosMedicos() {
      if (this.$store.state.listaCatalogoServciosMedicos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoServiciosMedicos/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoServciosMedicos = catalogo;
      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoMotivosConsulta() {
      if (this.$store.state.listaCatalogoMotivosConsulta.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMotivosConsulta/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMotivosConsulta = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstadioDiagnostico() {
      if (this.$store.state.listaCatalogoEstadioDiagnostico.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstadioDiagnostico/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstadioDiagnostico = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoFrecuenciaAdministracion() {
      if (this.$store.state.listaCatalogoFrecuenciaAdministracion.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoFrecuenciaAdministracion/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoFrecuenciaAdministracion = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoDuracionTratamiento() {
      if (this.$store.state.listaCatalogoDuracionTratamiento.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoDuracionTratamiento/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoDuracionTratamiento = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoViasAdministracion() {
      if (this.$store.state.listaCatalogoViasAdministracion.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoViasAdministracion/");
        let catalogo = response.data;
        this.$store.state.listaCatalogoViasAdministracion = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    moveFab(ev) {
      this.draggingFab = ev.isFirst !== true && ev.isFinal !== true

      this.fabPos = [
        this.fabPos[0] - ev.delta.x,
        this.fabPos[1] - ev.delta.y
      ]
    },
  }
}
</script>

<style>
.fab-custom-icon .q-btn--fab {
  background: url('~@/assets/icono_constanza_blanco.png') no-repeat center center;
  background-size: contain;
  width: 56px;
  height: 56px;
}

.fab-opened .q-btn--fab {
  background: none;
}
</style>

<template>
    <div class="row q-col-gutter-sm">

        <!-- DIALOG MEDICAMENTOS -->
        <q-dialog v-model="dialogMedicamentos" persistent>
            <q-card>
                <q-bar class="bg-primary text-white">
                    <div>Medicamentos</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>BUSQUEDA DE MEDICAMENTOS</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <!-- <div class="col-12">
                            <q-select filled v-model="medicamento" use-input input-debounce="0" label="Sustancia activa"
                                :options="itemsfiltroTratamiento" @filter="filtroTratamientoSustancia" fill-input
                                hide-selected dense option-label="sustancia" new-value-mode="add" />
                        </div> -->
                        <div class="col-12">
                            <q-select filled v-model="medicamento" use-input input-debounce="0" label="Medicamento"
                                :options="itemsfiltroTratamiento" @filter="filtroTratamiento" fill-input hide-selected
                                dense option-label="medicamentoCompleto" new-value-mode="add" />
                        </div>
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>DETALLES</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <div class="col-12">
                            <q-input v-model="medicamento.posologia" dense filled label="Posología">
                            </q-input>
                        </div>
                        <div class="col-12">
                            <q-input v-model="medicamento.presentacion" dense filled label="Presentación" />
                        </div>
                        <div class="col-12">
                            <q-input v-model="medicamento.dosis" type="number" dense filled label="Dosis" />
                        </div>

                        <div class="col-12">
                            <q-input v-model="medicamento.frecuenciaAdministracion" type="number" dense filled
                                label="Cada" />
                        </div>
                        <div class="col-12">
                            <q-select filled v-model="medicamento.frecuenciaAplicacion"
                                label="Frecuencia de Administración" :options="itemsFrecuenciaAdministracion" fill-input
                                dense />
                        </div>
                        <div class="col-12">
                            <q-input v-model="medicamento.duracion" type="number" dense filled
                                label="Por cuanto tiempo" />
                        </div>

                        <div class="col-12">
                            <q-select filled v-model="medicamento.temporalidad" label="Temporalidad del tratamiento"
                                :options="itemsDuracionTratamiento" fill-input dense />
                        </div>

                        <div class="col-12">
                            <q-select filled v-model="medicamento.viaAdministracion" label="Vía de Administración"
                                :options="itemsViasAdministracion" fill-input dense />
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarMedicamento()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- SOLICITUD DE INTERCONSULTA -->
        <q-dialog v-model="dialogSolicitudInterconsulta" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Solicitud de Interconsulta</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select v-model="solicitud.servicio" emit-value map-options @filter="filtroServcios"
                                :options="itemsfiltroServicio" use-input input-debounce="0" dense filled
                                label="Servicio" virtual-scroll-slice-size="1" new-value-mode="add" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input v-model="solicitud.doctor" dense filled label="Doctor" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input v-model="solicitud.telefono" dense filled label="Teléfono">
                                <!-- <template v-slot:after>
                    <q-btn round color="primary" icon="mdi-plus" @click="agregarServicio()">
                        <q-tooltip>
                            Agregar
                        </q-tooltip>
                    </q-btn>
                </template> -->
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarServicio()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>DIAGNÓSTICO Y PLAN</div>
                <q-space />
            </q-bar>
        </div> -->

        <div class="col-12 ">
            <h2 class="doc-h2">Diagnóstico y Plan</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-select dense filled v-model="consulta.diagnosticos" use-input use-chips multiple input-debounce="0"
                @new-value="createValueDiagnosticos" :options="itemsfiltroDiagnosticos" @filter="filtroDiagnosticos"
                label="Diagnosticos" />
        </div>

        <!-- <div class="col-12 q-mt-lg">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>DIETA Y HABITOS</div>
                <q-space />
            </q-bar>
        </div> -->

        <div class="col-12 ">
            <h2 class="doc-h2">Plan no Farmacológico</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-input v-model="consulta.dietaHabitos" label="Dieta y habitos" filled type="textarea" />
        </div>
        <div class="col-12 q-mt-lg row">
            <q-card class="full-width">
                <q-tabs v-model="tab" style="background-color: #1A4161;" dense class=" text-white shadow-2"
                    active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
                    <q-tab name="medicamentos" label="Medicamentos" icon="mdi-pill-multiple" />
                    <q-tab name="interconsulta" label="Solicitud de Interconsulta" icon="mdi-doctor" />
                    <q-tab name="hospitalizacion" label="Orden Hospitalización" icon="mdi-hospital-box" />
                    <q-tab name="orden" label="Orden de Laboratorio o Imagen" icon="mdi-file-document-edit" />
                </q-tabs>

                <q-separator />

                <q-tab-panels v-model="tab" animated>

                    <q-tab-panel name="medicamentos">
                        <div class="col-12 q-mt-lg row">
                            <div class="doc-h2">Medicamentos</div>
                            <q-space />
                            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                                style="background:#1A4161; color:white" label="Agregar medicamento"
                                @click="dialogMedicamentos = true" />
                            <div class="element-linea"></div>
                        </div>
                        <div class="col-12">
                            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                                :data="consulta.medicamentos.medicamentos" :columns="columnsMedicamentos"
                                :rows-per-page-options="[0]" row-key="medicamento" :pagination="initialPagination"
                                separator="cell" no-data-label="Sin datos disponibles">
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td auto-width key="acciones">
                                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                                @click="eliminarMedicamento(props.row)" icon="mdi-delete">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                                            </q-btn>
                                        </q-td>
                                        <q-td key="sustancia" :props="props">{{ props.row.sustancia }}</q-td>
                                        <q-td key="medicamento" :props="props">{{ props.row.medicamento }}</q-td>
                                        <q-td key="posologia" :props="props">{{ props.row.posologia }}</q-td>
                                        <q-td key="presentacion" :props="props">{{ props.row.presentacion }}</q-td>
                                        <q-td key="dosis" :props="props">{{ props.row.dosis }}</q-td>
                                        <q-td key="frecuenciaAdministracion" :props="props">{{
            props.row.frecuenciaAdministracion
        }}</q-td>
                                        <q-td key="frecuenciaAplicacion" :props="props">{{
            props.row.frecuenciaAplicacion }}</q-td>
                                        <q-td key="duracion" :props="props">{{ props.row.duracion }}</q-td>
                                        <q-td key="temporalidad" :props="props">{{ props.row.temporalidad }}</q-td>
                                        <q-td key="viaAdministracion" :props="props">{{ props.row.viaAdministracion
                                            }}</q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="consulta.medicamentos.otrasIndicaciones" label="Otras indicaciones" filled
                                type="textarea" />
                        </div>
                    </q-tab-panel>

                    <q-tab-panel name="interconsulta">
                        <div class="col-12 q-mt-lg row">
                            <div class="doc-h2">Solicitud de Interconsulta</div>
                            <q-space />
                            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                                style="background:#1A4161; color:white" label="Agregar solicitud"
                                @click="dialogSolicitudInterconsulta = true" />
                            <div class="element-linea"></div>
                        </div>

                        <div class="col-12">
                            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                                :data="consulta.solicitudInterconsulta" :columns="columnsSolicitud"
                                :rows-per-page-options="[0]" separator="cell" no-data-label="Sin datos disponibles"
                                row-key="servicio">
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td auto-width key="acciones">
                                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                                @click="eliminarServicio(props.row)" icon="mdi-delete">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                                            </q-btn>
                                        </q-td>
                                        <q-td key="servicio" :props="props">{{ props.row.servicio }}</q-td>
                                        <q-td key="doctor" :props="props">{{ props.row.doctor }}</q-td>
                                        <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </q-tab-panel>

                    <q-tab-panel name="hospitalizacion" class=" q-col-gutter-sm">
                        <div class="col-12 ">
                            <h2 class="doc-h2">Orden de Hospitalización</h2>
                            <div class="element-linea"></div>
                        </div>

                        <div class="col-12 col-md-6">
                            <q-input v-model="consulta.ordenHospitalizacion.hospital" dense filled label="Hospital" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input v-model="consulta.ordenHospitalizacion.motivoInternamiento" dense filled
                                label="Motivo de internamiento" />
                        </div>

                    </q-tab-panel>

                    <q-tab-panel name="orden">
                        <OrdenLaboratorioImagen></OrdenLaboratorioImagen>

                    </q-tab-panel>
                </q-tab-panels>
            </q-card>
        </div>






        <!-- <div class="col-12  q-mt-lg">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>SOLICITUD DE INTERCONSULTA</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogSolicitudInterconsulta = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->

        <div class="col-12 ">
            <h2 class="doc-h2">Pronóstico</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select dense filled v-model="consulta.pronosticos" use-input use-chips multiple input-debounce="0"
                @new-value="createValuePronosticos" :options="itemsfiltroPronosticos" @filter="filtroPronosticos"
                label="Pronóstico" />
        </div>




        <div class="col-12 ">
            <h2 class="doc-h2">indicaciones</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 ">
            <q-input v-model="consulta.ordenHospitalizacion.indicaciones.dieta" dense filled label="Dieta" />
        </div>
        <div class="col-12 ">
            <q-input v-model="consulta.ordenHospitalizacion.indicaciones.medidasGenerales" dense filled
                label="Medidas Generales" />
        </div>
        <div class="col-12 ">
            <q-input v-model="consulta.ordenHospitalizacion.indicaciones.liquidosIntravenosos" dense filled
                label="Líquidos Intravenosos" />
        </div>
        <div class="col-12 ">
            <q-input v-model="consulta.ordenHospitalizacion.indicaciones.medicamentos" dense filled
                label="Medicamentos" />
        </div>
        <div class="col-12 ">
            <q-input v-model="consulta.ordenHospitalizacion.indicaciones.estudios" dense filled label="Estudios" />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import OrdenLaboratorioImagen from './OrdenLaboratorioImagen.vue'
import { QSpinnerCube } from 'quasar'
export default {
    components: {
        OrdenLaboratorioImagen
    },
    data() {
        return {
            tab: 'medicamentos',
            itemsfiltroDiagnosticos: null,
            itemsfiltroTratamiento: null,
            medicamentoDefault: {
                medicamento: ''
            },
            initialPagination: {
                descending: false,
                page: 1,
                rowsPerPage: 10
            },
            medicamento: {
                sustancia: '',
                medicamento: '',
                posologia: '',
                presentacion: '',
                dosis: 0,
                frecuenciaAdministracion: 0,
                frecuenciaAplicacion: '',
                duracion: 0,
                temporalidad: '',
                viaAdministracion: '',
                medicamentoCompleto: '',
            },
            columnsMedicamentos: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'sustancia', align: 'center', label: 'Sustancia', field: 'sustancia', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'medicamento', align: 'center', label: 'Nombre Comercial', field: 'medicamento', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'posologia', align: 'center', label: 'Posología', field: 'posologia', sortable: true },
                { name: 'presentacion', align: 'center', label: 'Presentación', field: 'presentacion', sortable: true, },
                { name: 'dosis', align: 'center', label: 'Dosis', field: 'dosis', sortable: true, },
                { name: 'frecuenciaAdministracion', align: 'center', label: 'Cada', field: 'frecuenciaAdministracion', sortable: true, },
                { name: 'frecuenciaAplicacion', align: 'center', label: 'Frecuencia de Administración', field: 'frecuenciaAplicacion', sortable: true, },
                { name: 'duracion', align: 'center', label: 'Por', field: 'duracion', sortable: true, },
                { name: 'temporalidad', align: 'center', label: 'Duración del tratamiento', field: 'temporalidad', sortable: true, },
                { name: 'viaAdministracion', align: 'center', label: 'Vía de administración', field: 'viaAdministracion', sortable: true, },
            ],

            solicitud: {
                servicio: '',
                doctor: '',
                telefono: '',
            },
            itemsfiltroServicio: null,
            columnsSolicitud: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'servicio', align: 'center', label: 'Servicio', field: 'servicio', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'doctor', align: 'center', label: 'Doctor', field: 'doctor', sortable: true },
                { name: 'telefono', align: 'center', label: 'Teléfono', field: 'telefono', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
            ],
            dialogMedicamentos: false,
            dialogSolicitudInterconsulta: false,

            itemsfiltroPronosticos: null
        }
    },
    computed: {
        consulta() {
            return this.$store.state.consultaStore.diagnosticoPlan;
        },
        itemsMedicamentos() {
            return this.$store.state.listaCatalogoMedicamentosObj
        },
        itemsDiagnosticosCIE() {
            return this.$store.state.listaCatalogoDiagnosticosString
        },
        itemsServiciosMedicos() {
            return this.$store.state.listaCatalogoServciosMedicos
        },
        itemsFrecuenciaAdministracion() {
            return this.$store.state.listaCatalogoFrecuenciaAdministracion
        },
        itemsDuracionTratamiento() {
            return this.$store.state.listaCatalogoDuracionTratamiento
        },
        itemsViasAdministracion() {
            return this.$store.state.listaCatalogoViasAdministracion
        },
        itemsPronosticos() {
            return this.$store.state.listaCatalogoPronosticos
        }
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroPronosticos(val, update) {
            let stringOptions = this.itemsPronosticos
            if (val === '') {
                update(() => {
                    this.itemsfiltroPronosticos = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.itemsfiltroPronosticos = stringOptions.filter(v => v.toLowerCase().indexOf(needle) > -1)
            })
        },
        filtroTratamiento(val, update) {
            let stringOptions = this.itemsMedicamentos
            if (val === '') {
                update(() => {
                    this.itemsfiltroTratamiento = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.itemsfiltroTratamiento = stringOptions.filter(v => v.medicamentoCompleto.toLowerCase().indexOf(needle) > -1)
            })
        },
        filtroTratamientoSustancia(val, update) {
            let stringOptions = this.itemsMedicamentos
            if (val === '') {
                update(() => {
                    this.itemsfiltroTratamiento = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.itemsfiltroTratamiento = stringOptions.filter(v => v.sustancia.toLowerCase().indexOf(needle) > -1)
            })
        },
        filtroDiagnosticos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroDiagnosticos = this.itemsDiagnosticosCIE.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroDiagnosticos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueDiagnosticos(val, done) {
            if (val.length > 0) {
                if (!this.itemsDiagnosticosCIE.includes(val)) {
                    this.itemsDiagnosticosCIE.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValuePronosticos(val, done) {
            if (val.length > 0) {
                if (!this.itemsPronosticos.includes(val)) {
                    this.itemsPronosticos.push(val)
                }
                done(val, 'toggle')
            }
        },
        asignarMedicamento() {
            this.medicamento.medicamento = this.medicamentoDefault.medicamento
            this.medicamento.dosis = this.medicamentoDefault.dosis
            this.medicamento.presentacion = this.medicamentoDefault.presentacion
        },
        agregarMedicamento() {
            console.log(this.medicamento)
            if (this.medicamento.medicamento == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre comercial del medicamento.' })
                return;
            }

            if (this.medicamento.sustancia == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre de la sustancia activa del medicamento.' })
                return;
            }

            if (this.medicamento.posologia == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la posología del medicamento.' })
                return;
            }

            if (this.medicamento.presentacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la presentación del medicamento.' })
                return;
            }

            if (this.medicamento.dosis <= 0 || this.medicamento.dosis == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la dosis.' })
                return;
            }

            if (this.medicamento.frecuenciaAdministracion <= 0 || this.medicamento.frecuenciaAdministracion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la frecuencia de la administración.' })
                return;
            }

            if (this.medicamento.frecuenciaAplicacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la frecuencia de la aplicación.' })
                return;
            }
            if (this.medicamento.duracion <= 0 || this.medicamento.duracion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique por cuanto tiempo.' })
                return;
            }

            if (this.medicamento.temporalidad == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique temporalidad del tratamiento.' })
                return;
            }

            if (this.medicamento.viaAdministracion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la viá de administración.' })
                return;
            }
            this.$store.state.consultaStore.diagnosticoPlan.medicamentos.medicamentos.push(this.medicamento);

            this.medicamento = {
                sustancia: '',
                medicamento: '',
                posologia: '',
                presentacion: '',
                dosis: 0,
                frecuenciaAdministracion: 0,
                frecuenciaAplicacion: '',
                duracion: 0,
                temporalidad: '',
                viaAdministracion: ''
            }

        },

        eliminarMedicamento(data) {
            let editedIndex = this.$store.state.consultaStore.diagnosticoPlan.medicamentos.medicamentos.indexOf(data)
            this.$store.state.consultaStore.diagnosticoPlan.medicamentos.medicamentos.splice(editedIndex, 1)
        },

        filtroServcios(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroServicio = this.itemsServiciosMedicos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroServicio.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        agregarServicio() {
            if (this.solicitud.servicio == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un servicio' })
                return;
            }
            if (this.solicitud.doctor == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del Doctor' })
                return;
            }

            this.$store.state.consultaStore.diagnosticoPlan.solicitudInterconsulta.push(this.solicitud);

            this.solicitud = {
                servicio: '',
                doctor: '',
                telefono: '',
            }
        },

        eliminarServicio(data) {
            let editedIndex = this.$store.state.consultaStore.diagnosticoPlan.solicitudInterconsulta.indexOf(data)
            this.$store.state.consultaStore.diagnosticoPlan.solicitudInterconsulta.splice(editedIndex, 1)
        },
    }
}
</script>
<style>
.header-tabla thead th {
    border-bottom: 4px solid #9104C2;
}
</style>
import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=7bef24b8"
import script from "./MainPage.vue?vue&type=script&lang=js"
export * from "./MainPage.vue?vue&type=script&lang=js"
import style0 from "./MainPage.vue?vue&type=style&index=0&id=7bef24b8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QBadge,QMenu,QList,QItem,QItemSection,QAvatar,QItemLabel,QIcon,QSeparator,QDrawer});qInstall(component, 'directives', {Ripple});

import { render, staticRenderFns } from "./Perfil.vue?vue&type=template&id=0065a774"
import script from "./Perfil.vue?vue&type=script&lang=js"
export * from "./Perfil.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QCard,QCardSection,QImg,QInput,QBtn,QIcon,QPopupProxy,QTime,QSelect,QColor,QEditor});qInstall(component, 'directives', {ClosePopup});

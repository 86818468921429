<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated>
      <q-toolbar style="background:#1A4161; color:white" class="q-px-xl">
        <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
        <!-- <q-btn flat @click="leftDrawerOpen = !leftDrawerOpen" round dense icon="menu" /> -->
        <q-toolbar-title>
          <div class="text-h6 text-weight-bolder">One Analítica</div>
        </q-toolbar-title>
        <div class="text-h6 q-mr-lg">{{ token.username }}</div>
        <q-btn flat round dense icon="mdi-bell" class="q-mr-md">
          <q-badge color="red" floating>0</q-badge>
          <q-menu>
            <div class="q-pa-xl text-h5 text-center text-grey">
              Sin Notificaciones
            </div>
          </q-menu>
        </q-btn>
        <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
      </q-toolbar>
    </q-header>

    <!-- DRAWER DERECHO -->
    <q-drawer show-if-above behavior="mobile" v-model="drawerPerfil" side="right" bordered>
      <drawerPerfil></drawerPerfil>
    </q-drawer>

    <!-- DRAWER IZQUIERDO -->
    <!-- <q-drawer show-if-above @mouseover="miniState = false" @mouseout="miniState = false" v-model="leftDrawerOpen"
      :mini="miniState" :width="200" :breakpoint="500" bordered content-class="bg-blue-1">
      <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
        <q-list padding>
          <q-item active clickable v-ripple @click="irPacientes">
            <q-item-section avatar>
              <q-icon size="md" name="mdi-account-plus" />
            </q-item-section>
            <q-item-section> Pacientes </q-item-section>
          </q-item>
          <q-separator />

          <q-item active clickable v-ripple @click="irConsultas()">
            <q-item-section avatar>
              <q-icon size="md" name="mdi-notebook-plus" />
            </q-item-section>
            <q-item-section> Consultas </q-item-section>
          </q-item>
          <q-separator />

          <q-item active clickable v-ripple @click="irConstanza()">
            <q-item-section avatar>
              <q-icon size="md" name="mdi-forum-plus" />
            </q-item-section>
            <q-item-section> Constanza </q-item-section>
          </q-item>
          <q-separator />

          <q-item active clickable v-ripple @click="irAgenda()">
            <q-item-section avatar>
              <q-icon size="md" name="mdi-calendar-month" />
            </q-item-section>
            <q-item-section> Agenda </q-item-section>
          </q-item>
          <q-separator />

          <q-item active clickable v-ripple @click="irConsultorios()">
            <q-item-section avatar>
              <q-icon size="md" name="mdi-hospital-building" />
            </q-item-section>
            <q-item-section> Consultorios </q-item-section>
          </q-item>
          <q-separator />
        </q-list>
      </q-scroll-area>
    </q-drawer> -->

    <q-page-container>
      <router-view />
    </q-page-container>

    <q-footer style="height: 30px" bordered class="bg-dark text-white">
      <q-toolbar>
        <q-toolbar-title> </q-toolbar-title>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue";

export default {
  name: "MainLayout",
  components: {
    drawerPerfil,
  },
  data() {
    return {
      drawerPerfil: false,
      isDarkMode: false,
      leftDrawerOpen: false,
      drawer: false,
      miniState: false,
    };
  },

  watch: {
    isDarkMode(val) {
      this.$q.dark.set(val);
    },
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  created() { },
  methods: {
    irPacientes() {
      this.$router.push({ name: "ListaPacientes" });
    },
    irConsultas() {
      this.$router.push({ name: "ListaConsultas" });
    },
    irConstanza() {
      this.$router.push({ name: "Constanza" });
    },
    irAgenda() {
      this.$router.push({ name: "Agenda" });
    },
    irConsultorios() {
      this.$router.push({ name: "ListaConsultorios" });
    },
  },
};
</script>

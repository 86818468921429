<template>
    <div class=" q-pa-lg q-pt-xl" :style="{
        backgroundImage: `url(${isDark ? darkImage : lightImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        paddingTop: '80px' /* Ocupa toda la pantalla */
    }">
        <!-- DIALOG DE LA UNIDAD MEDICA -->
        <q-dialog v-model="dialogUnidadMedica" persistent transition-show="scale" transition-hide="scale">
            <UnidadMedica @cierraVentana="dialogUnidadMedica = false"></UnidadMedica>
        </q-dialog>

        <!-- CABECERA CON LOS BOTONES -->
        <div class="row	flex-left ">
            <!-- LOGO DE ONE ANALITICA -->
            <div class="col-12 col-md-6 col-sm-6 text-left ">
                <span v-if="$store.state.isDark == true"
                    class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
                    style="border-radius: 10px; width: 230px; height: 50px;">
                </span>
                <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
                    style=" width: 230px; height: 50px;">
                </span>
            </div>
            <!-- BOTONES DE ACCION -->
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn size="md" class="btn-estilos q-mr-sm" icon="mdi-plus" unelevated rounded
                    style="background:#1A4161; color:white" label="Nueva unidad médica" @click="nuevaUnidadMedica()" />
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-update"
                    @click="GetUnidadesMedicas()" />
            </div>
            <div class="element-linea"></div>

        </div>
        <!-- TABLA CON LAS UNIDADES MEDICAS -->
        <div>
            <q-table title="Unidad médica" :data="dataUnidadesMedicas" :columns="columns" row-key="_id"
                :filter="filter" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <template v-if="$store.state.isDark">
                                <q-btn size="md" style="color:#686868" rounded flat dense
                                    @click="editarUnidadMedica(props.row)" icon="mdi-pencil">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Editar</q-tooltip>
                                </q-btn>
                            </template>
                            <template v-if="!$store.state.isDark">
                                <q-btn size="md" style="color:#1A4161" rounded flat dense
                                    @click="editarUnidadMedica(props.row)" icon="mdi-pencil">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Editar</q-tooltip>
                                </q-btn>
                            </template>
                        </q-td>
                        <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                        <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                        <q-td key="razon_social" :props="props">{{ props.row.razon_social }}</q-td>
                        <q-td key="codigo" :props="props">{{ props.row.codigo }}</q-td>
                        <q-td key="licencia" :props="props">{{ props.row.licencia }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import UnidadMedica from './UnidadMedica.vue';

export default {
    components: {
        UnidadMedica
    },
    data() {
        return {
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'razon_social', align: 'center', label: 'Razón social', field: 'razon_social' },
                { name: 'codigo', align: 'center', label: 'Código de referencia', field: 'codigo', sortable: true },
                { name: 'licencia', align: 'center', label: 'Licencia', field: 'licencia', sortable: true },
            ],
            filter: "",
            pagination: {
                descending: false,
                page: 1,
                rowsPerPage: 10
            },

            dialogUnidadMedica: false,
            lightImage: '', // URL de imagen para el tema claro
            darkImage: require('../../../assets/fondo-13.jpg'), // URL de imagen para el tema oscuro
        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        dataUnidadesMedicas() {
            return this.$store.state.listUnidadMedicaStore;
        },

        isDark() {
            return this.$q.dark.isActive;
        },
    },

    watch: {

    },

    created() {
        this.Iniciales();
    },

    mounted() {

    },

    methods: {
        async Iniciales() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando...', messageColor: 'white' })
            const tareas = [
                this.GetUnidadesMedicas(),
            ];
            const paso2 = await Promise.all(tareas);
            this.$q.loading.hide()
        },

        async GetUnidadesMedicas() {
            try {
                this.$store.state.listUnidadMedicaStore = [];
                let response = await axios.get(`UnidadMedica/GetUnidadesMedicasAsync/`);
                this.$store.state.listUnidadMedicaStore = [...response.data];
            } catch (error) {
                console.log(error);
            }
        },

        nuevaUnidadMedica() {
            const objUnidadMedica = {
                _id: "",
                nombre: null,
                rfc: null,
                razon_social: null,
                liga_maps: null,
                codigo: null,
                licencia: null,
                _id_usuario_creador: this.token._id,
                fecha_creacion: new Date()
            }

            this.$store.state.unidadMedicaStore = { ...objUnidadMedica }
            this.dialogUnidadMedica = true;
        },

        editarUnidadMedica() {

        },

    }
}
</script>